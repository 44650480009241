<template>
    <v-content>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex xs12 sm10 md6 lg3 style="padding-top:200px;">
                    <v-card class="elevation-12">
                        <v-row>
                            <v-col class="text-center">
                                <img :src="require('@/assets/img/nuform-logo.png')"
                                     width="100%">
                            </v-col>
                        </v-row>
                        <v-form v-model="valid" @submit.prevent="login()" ref="form">
                            <v-toolbar color="primary" dark flat>
                                <v-toolbar-title>
                                    Nuform Sales Opportunities
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <v-alert :type="alertType" v-model="alertDisplay" dismissible>{{alertMessage}}</v-alert>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-text-field name="username"
                                              label="Username"
                                              type="text"
                                              :rules="[v => !!v || 'Username is required']"
                                              v-model="username"
                                              required></v-text-field>
                                <v-text-field id="password"
                                              name="password"
                                              type="password"
                                              label="Password"
                                              :rules="[v => !!v || 'Password is required']"
                                              v-model="password"
                                              required></v-text-field>
                            </v-card-text>
                            <v-card-actions style="padding:0px">
                                <v-flex xs8 offset-xs1 v-if="submitted" class="pt-0">
                                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-card-title class="pt-0">
                                    <v-btn type="submit" color="primary">Log in</v-btn>
                                </v-card-title>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        name: "Login",
        components: {},
        data: () => ({
            valid: false,
            username: "",
            password: "",
            submitted: false,
            showPassword: false,
            alertType: null,
            alertMessage: null,
            alertDisplay: false
        }),
        methods: {
            login() {
                this.alertDisplay = false;
                if (this.$refs.form.validate()) {
                    this.submitted = true;
                    const username = this.username;
                    const password = this.password;

                    this.$store
                        .dispatch("login", { username, password })
                        .then(() => {
                            this.$router.push("/");
                        })
                        .catch(err => {
                            console.log(err);
                            this.submitted = false;
                            this.alertType = "error";
                            this.alertMessage = "Login Failed!";
                            this.alertDisplay = true;
                        });
                }
            }
        }
    };
</script>
